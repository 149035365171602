<template>
  <b-card>
    <title-text />
    <content-text />
    <hr>
    <empty-title />
    <empty-content />
    <button-label />
    <button-url />
  </b-card>
</template>
<script>
import TitleText from '@/views/Admin/Estate_configs/elements/TitleText.vue'
import ContentText from '@/views/Admin/Estate_configs/elements/ContentText.vue'
import EmptyTitle from '@/views/Admin/Estate_configs/elements/EmptyTitle.vue'
import EmptyContent from '@/views/Admin/Estate_configs/elements/EmptyContent.vue'
import ButtonLabel from '@/views/Admin/Estate_configs/elements/ButtonLabel.vue'
import ButtonUrl from '@/views/Admin/Estate_configs/elements/ButtonUrl.vue'
import { BCard } from 'bootstrap-vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    ContentText,
    TitleText,
    EmptyTitle,
    EmptyContent,
    ButtonLabel,
    ButtonUrl,
  },
}
</script>
